<template>
	<v-list-item-content>
		<v-list-item-title
			:class="['adjust_type'].includes(field_type) ? 'text-x-large font-weight-bold' : ''"
		>
			<template v-if="tooltip_required == 1">
				<div v-b-tooltip.hover :id="`selection${id(item)}`" v-text="name(item)"></div>
				<b-tooltip
					variant="dark"
					:target="`selection${id(item)}`"
					triggers="hover"
					class="btooltip"
				>
					{{ tooltip(item) }}
				</b-tooltip>
			</template>
			<div v-show="tooltip_required == 0" v-text="name(item)"></div>
		</v-list-item-title>
		<v-list-item-subtitle
			class="text-warning"
			v-text="customer == 1 ? $t('COMMON.STATUS.SUSPEND') : $t('COMMON.STATUS.WGI')"
			v-if="
				['marking', 'delivery_address'].includes(field_type) &&
				typeof item[status_key] != 'undefined' &&
				status(item) == 3
			"
		>
		</v-list-item-subtitle>
		<v-list-item-subtitle
			v-if="field_type == 'inv_quotation'"
			class="text-teal"
			v-html="
				`${$t('TRANSACTION.INVOICE_MANAGEMENT.TITLE_EFFECTIVE', {
					expire: $CoreSettingsJs.emptyStringValidation(item.effective_on)
						? `-`
						: item.effective_on,
				})}`
			"
		></v-list-item-subtitle>
		<v-list-item-subtitle
			v-if="field_type == 'category'"
			v-html="item.cat_type_name"
		></v-list-item-subtitle>
		<v-list-item-subtitle
			class="text-teal"
			v-if="field_type == 'tax'"
			v-html="`${$Common.numberFormatCurrency(item.rate)}%`"
		></v-list-item-subtitle>
		<v-list-item-subtitle
			v-if="['customer_min_markup', 'customer_markup'].includes(field_type)"
			v-html="item.markup"
		></v-list-item-subtitle>
		<v-list-item-subtitle
			class="text-teal"
			v-if="
				(field_type == 'tt_category' &&
					!$CoreSettingsJs.emptyStringValidation(item.deposit_range_from, 1)) ||
				!$CoreSettingsJs.emptyStringValidation(item.deposit_range_to, 1)
			"
		>
			<span
				v-if="
					!$CoreSettingsJs.emptyStringValidation(item.deposit_range_from) &&
					!$CoreSettingsJs.emptyStringValidation(item.deposit_range_to)
				"
				v-html="
					$t('COMMON.TXT_RANGE', {
						from: $CoreSettingsJs.onFormatterNo(item.deposit_range_from),
						to: $CoreSettingsJs.onFormatterNo(item.deposit_range_to),
					})
				"
			></span>
			<span
				v-if="
					!$CoreSettingsJs.emptyStringValidation(item.deposit_range_from, 1) &&
					$CoreSettingsJs.emptyStringValidation(item.deposit_range_to)
				"
				v-html="
					$t('COMMON.TXT_ABV', {
						from: $CoreSettingsJs.onFormatterNo(item.deposit_range_from),
					})
				"
			></span>
			<span
				v-if="
					$CoreSettingsJs.emptyStringValidation(item.deposit_range_from) &&
					!$CoreSettingsJs.emptyStringValidation(item.deposit_range_to, 1)
				"
				v-html="
					$t('COMMON.TXT_BELOW', {
						to: $CoreSettingsJs.onFormatterNo(item.deposit_range_to),
					})
				"
			></span>
		</v-list-item-subtitle>	
		<v-list-item-subtitle
			v-if="['customer'].includes(field_type)"
			v-html="item.referral_salesman"
		></v-list-item-subtitle>
	</v-list-item-content>
</template>

<style></style>

<script>
export default {
	props: {
		item: null,
		text_key: {
			type: String,
			default() {
				return "name";
			},
		},
		field_type: {
			type: String,
			default() {
				return null;
			},
		},
		status_key: {
			type: String,
			default() {
				return "status";
			},
		},
		customer: {
			type: Number,
			default() {
				return 0;
			},
		},
		tooltip_required: {
			type: Number,
			default() {
				return 0;
			},
		},
		tooltip_key: {
			type: String,
			default() {
				return null;
			},
		},
		value_key: {
			type: String,
			default() {
				return "id";
			},
		},
	},
	methods: {
		id(item) {
			return item[this.value_key];
		},
		name(item) {
			if (this.field_type == "quotation_status") {
				return `${item.name} (${item.quote_total})`;
			} else if (this.field_type == "customer") {
				return `${item.id} - ${item.name}`;
			} else {
				return item[this.text_key];
			}
		},
		status(item) {
			return item[this.status_key];
		},
		tooltip(item) {
			return item[this.tooltip_key];
		},
	},
};
</script>
