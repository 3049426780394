export default {
	state: {
		epoSearchForm: sessionStorage.epoSearchForm ? JSON.parse(sessionStorage.epoSearchForm) : null,
		invSearchForm: sessionStorage.invSearchForm ? JSON.parse(sessionStorage.invSearchForm) : null,
		companySearchForm: sessionStorage.companySearchForm ? JSON.parse(sessionStorage.companySearchForm) : null,


		//Add On State Variable
		deliveryAddrSearchForm: sessionStorage.deliveryAddrSearchForm ? JSON.parse(sessionStorage.deliveryAddrSearchForm) : null,
		customerSearchForm: sessionStorage.customerSearchForm ? JSON.parse(sessionStorage.customerSearchForm) : null,
		docNumSearchForm: sessionStorage.docNumSearchForm ? JSON.parse(sessionStorage.docNumSearchForm) : null,
	},
	getters: {
	},
	actions: {
		setEpoSearchForm(state, payload) {
			state.commit("setEpoSearchForm", payload);
		},
		setInvSearchForm(state, payload) {
			state.commit("setInvSearchForm", payload);
		},
		//Add Action
		setDeliveryAddrSearchForm(state, payload) {
			state.commit("setDeliveryAddrSearchForm", payload)
		},
		setCustomerSearchForm(state, payload) {
			state.commit("setCustomerSearchForm", payload)
		},
		setDocNumSearchForm(state, payload) {
			state.commit("setDocNumSearchForm", payload)
		},
		setCompanySearchForm(state, payload){
			state.commit("setCompanySearchForm", payload)
		}
	},
	mutations: {
		setEpoSearchForm(state, payload) {
			state.epoSearchForm = payload;
			sessionStorage.epoSearchForm = JSON.stringify(payload);
		},
		setInvSearchForm(state, payload) {
			state.invSearchForm = payload;
			sessionStorage.invSearchForm = JSON.stringify(payload);
		},
		setDeliveryAddrSearchForm(state, payload) {
			state.deliveryAddrSearchForm = payload;
			sessionStorage.deliveryAddrSearchForm = JSON.stringify(payload);
		},
		setCustomerSearchForm(state, payload) {
			state.customerSearchForm = payload;
			sessionStorage.customerSearchForm = JSON.stringify(payload);
		},
		setDocNumSearchForm(state, payload) {
			state.docNumSearchForm = payload;
			sessionStorage.docNumSearchForm = JSON.stringify(payload);
		},
		setCompanySearchForm(state, payload){
			state.companySearchForm = payload;
			sessionStorage.companySearchForm = JSON.stringify(payload);
		}
	

	}
};